import { Autocomplete, Button, TextField } from '@mui/material'
import React, { Component } from 'react'

var options = {
    // "AHG": "AHG (Artikelhauptgruppe)",
    // "ART": "ART (Artikel)",
}
export class Test extends Component {
    constructor(props) {
        super(props)
        this.state = {
             value: ""
        }
    }
    render() {
        return (
            <div>
                <Autocomplete
                    // disablePortal
                    options={Object.values(options)}
                    sx={{ width: 300 }}
                    value={options[this.state.value]}
                    defaultValue={"Hallo Welt"}
                    onChange={e => {
                        
                        this.setState({value: e.target.value});
                    }}
                    renderInput={(params) => <TextField {...params} label="Movie" />}
                />
                <Button onClick={() => {}}>Senden</Button>
            </div>
        )
    }
}

export default Test